import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBackward
} from '@fortawesome/free-solid-svg-icons'

const NotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const bakPage = () => navigate(-1);
    return (
        <div className="py-4 text-center">
            <h2>404</h2>
            <h4>Page NotFound</h4>
            <button onClick={bakPage} className='btn btn-sm btn-info mt-5'><FontAwesomeIcon icon={faBackward} /> {t('back to home')} </button>
        </div>
    );
}

export default NotFound;
