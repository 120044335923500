const axios = require('axios').default;
const config = require(`../config/${process.env.NODE_ENV}`).default;


const client = axios.create({
    baseURL: config.api
});

client.interceptors.request.use(
    config => {
        if (localStorage.getItem('token')) {
            config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        } else {
            localStorage.removeItem('token')
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

client.interceptors.response.use((response) => {
    return response;
}, (error) => {
    console.error('error.response.status::', error.response)
    if(error.response.status === 401){
        localStorage.removeItem('token')
    }
    return Promise.reject(error.message);
});
 
export default {
    client
};