import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

 
i18n
.use(initReactI18next)
.use(LanguageDetector)
.use(HttpApi)
.init({
  supportedLngs: ['en', 'th'],
  fallbackLng: "th",
  detection: {
    order: ['localStorage', 'path', 'cookie', 'htmlTag', 'subdomain'],
    caches: ['localStorage']
  },
  backend: {
    loadPath: '/assets/locales/{{lng}}/{{ns}}.json'
  }
}, (err, t) => {
  if (err) return console.log('something went wrong loading', err);
  t('key'); // -> same as i18next.t
});

export default i18n;